import React from "react";
import {ReactComponent as WarningIcon} from "../../assets/warning.svg";


export const HeaderText : any = {
    "Taxanders": <><b>Connecting Great Minds!</b> Find your Taxand colleagues here and go to market Together as Taxand! Here you can share your own profile and explore a world of possible connections with fellow Taxanders globally.  Please update your personal profile page with your contact information, expertise, photo, personal and professional biographies, so Taxanders can find you and your expertise here!</>,
    "Firms":<><b>Beyond Borders!</b> Here you will find links to all Taxand member firms so you can learn more about them and feel proud to belong to the truly global organisation that is Taxand!</>,
    'MyProfile':<><b>Tell us about you!</b> This is your personal profile page where you can share your personal brand and expertise with your fellow Taxanders!  What you include here will be found on the Professionals page. Please add your contact details, expertise, photo, personal and professional biography.</>,
    'Documents':(
        <>
            <b>Knowledge for all!</b> Dive into documents, resources, and insights carefully curated to elevate your practice. This is our shared document space with folders and content that can be open to all or set up for specific groups to view.  Here we will hold templates, presentations, information by specialism and more!  If you want to share other ideas? Please contact us at <a style={{color:"#3033A6"}} href="mailto:Taxand@taxand.com"><b>Taxand@taxand.com</b></a>. We need your help to build up new content!
            <br/>
            <span style={{display: "flex", alignItems: "center", paddingTop: "10px"}}>
                <WarningIcon style={{marginRight: "7px"}}/><b>All documents are for Taxand internal use only, and should not be circulated outside Taxand’s member firms.</b>
            </span></>
    )
}
