import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Post} from "../../../models/post";
import {notification, Result} from "antd";
import {Skeleton} from "@material-ui/lab";
import {TextInput} from "@mantine/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import {IconSearch} from "@tabler/icons";
import { ReactComponent as SearchErrorIcon } from "assets/search-error.svg";
import moment from "moment";
import './News.scss';

const News = () => {
    const {t} = useTranslation();

    // State variables
    const [posts, setPosts] = useState<Post[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchPosts, setSearchPosts] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [page, setPage] = useState(1); // Track the current page

    // Link of WordPress posts API
    const baseUrl = 'https://www.taxand.com';
    const postsEndpoint = '/wp-json/wp/v2/posts?categories=16&per_page=110';
    const perPage = 12;
    const endpoint = `${baseUrl}${postsEndpoint}&per_page=${perPage}&page=${page}`;

    // Fetch all the posts in WordPress by using the API
    const getPostsData = useCallback(async () => {
        setLoading(true);
        setIsSearching(false);
        try {
            const response = await fetch(endpoint);
            const resJson = await response.json();
            setPosts((prevPosts) => [...prevPosts, ...resJson]);
        }  catch (error) {
            notification['error']({
                message: 'Error',
                description: t('There was an error displaying the news, please refresh the page')
            });
        } finally {
            setLoading(false);
        }
    }, [endpoint, t]);

    useEffect(() => {
        getPostsData().then();
    }, [getPostsData, page]);

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    return (
        <>
            <h3 style={{fontWeight: "bold"}}>Latest news</h3>
            <TextInput
                placeholder="Search..."
                className="search-input"
                radius="md"
                size="md"
                rightSection={<IconSearch style={{ width: '18px', height: '18px', color: '#ced4da' }} />}
                value={searchPosts}
                onChange={(e) => {
                    setIsSearching(true);
                    setSearchPosts(e.target.value);
                    setPosts(posts.filter((post) => post.title.rendered?.toLowerCase().includes(e.target.value?.toLowerCase())));
                    if(e.target.value === "") {
                        setPosts([]);
                        getPostsData().then();
                    }
                }}
            />
            <section className="news-section">
                {isSearching && posts.length === 0 && (
                    <Result
                        icon={<SearchErrorIcon />}
                        title="No data found"
                        subTitle="Try adjusting your search to find what you're looking for."
                    />
                )}
                {posts.map((post) => (
                    <a key={post.id} href={post.link} target="_blank" rel="noopener noreferrer">
                        <article key={post.id} className="news-card">
                            <div className="news-img">
                                {loading ? (
                                    <Skeleton variant="rect" animation="wave" height={190} width="100%"/>
                                ) : (

                                        <img
                                            alt={ post.acf.image.title }
                                            src={post.acf.image.url + '.webp'}
                                        />
                                )}
                            </div>
                            <div className="news-content">
                                {loading ? (
                                    <>
                                        <Skeleton animation="wave" height={10} width="100%"/>
                                        <Skeleton animation="wave" height={10} width="100%"/>
                                        <Skeleton animation="wave" height={30} width="100%"/>
                                    </>
                                ) : (
                                    <>
                                            <h3 dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h3>

                                        <time>
                                            <CalendarTodayIcon className="calendar-icon"/>
                                            {moment(post.date).format('LL')}
                                        </time>
                                        <p>{post.yoast_head_json.description?.slice(0,60)}...</p>
                                    </>
                                )}
                            </div>
                        </article>
                    </a>
                ))}
            </section>
            <div className="news-pagination" hidden={isSearching}>
                <button onClick={() => handlePageChange(page + 1)} className="btn">
                    <span className="btn-text-one">Load More</span>
                    <span className="btn-text-two">Load More</span>
                </button>
            </div>
        </>
    );
};

export default News;