import { RoutesTypes } from "../../Types/Sidebar/routesType";
import PeopleIcon from "@material-ui/icons/People";
import Users from "../../routes/core/users/Users";
import { ROUTES } from "../../parameters/routes";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import BusinessIcon from "@material-ui/icons/Business";
import SupervisedUserCircleSharpIcon from "@material-ui/icons/SupervisedUserCircleSharp";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { ReactComponent as CalendarTodayIcon } from "assets/calendar2.svg";
import Events from "../../routes/core/events/Events";
import Approvals from "../../routes/core/approval/Approvals";
import Dashboard from "../../routes/core/dashboard/Dashboard";
import FirmsProfessionals from "routes/core/firmsProfessionals/FirmsProfessionals";
import Firms from "../../routes/core/firms/Firms";
import Profile from "routes/core/profile/Profile";
import Documents from "../../routes/core/documents/documents";
import News from "../../routes/core/news/News";
import { ReactComponent as FoldersIcon } from "assets/folders.svg";
import { ReactComponent as NewsIcon } from "assets/news.svg";
import { ReactComponent as DashboardIcon } from "assets/dashboard.svg";

export const AppRoutes : RoutesTypes[] = [
    {
        Title: "Dashboard",
        Path: ROUTES.dashboard,
        Icon: DashboardIcon,
        Component: Dashboard,
        userRight: ["Global administrator"]
    },
    {
        Title: "My Profile",
        Path: ROUTES.home,
        Icon: AccountCircleIcon,
        Component: Profile,
        userRight: ["*"]
    },
    {
        Title: "Taxanders",
        Path: ROUTES.users,
        Icon: PeopleIcon,
        Component: Users,
        userRight: ["*"]
    },
    {
        Title: "Firms",
        Path: ROUTES.firms,
        Icon: BusinessIcon,
        Component: Firms,
        userRight: ["Global administrator"]
    },
    {
      Title: "News",
      Path: ROUTES.news,
      Icon: NewsIcon,
      Component: News,
      userRight: ["*"]
    },
    {
        Title: "Events",
        Path: ROUTES.events,
        Icon: CalendarTodayIcon,
        Component: Events,
        userRight: ["*"]
    },
    {
        Title: "Documents",
        Path: ROUTES.documents,
        Icon: FoldersIcon ,
        Component: Documents,
        userRight: ["*"]
    },
    {
        Title: "Approvals",
        Path: ROUTES.approvals,
        Icon: GroupAddIcon,
        Component: Approvals,
        userRight: ["Global administrator"]
    },
    {
        Title: "My firm’s professionals",
        Path: ROUTES.professionalsFirm,
        Icon: SupervisedUserCircleSharpIcon,
        Component: FirmsProfessionals,
        userRight: ["Firm administrator", "Assistant"]
    }];
