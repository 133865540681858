import React, {ComponentType, FunctionComponent, SVGProps, useState} from 'react'
import {AppRoutes} from '../../Utils/Constants/RoutesConstants';
import logo from 'assets/TaxandBigIcon.svg';
import dataStore from 'stores/dataStore';
import {inject, observer} from "mobx-react";
import {Button, Modal} from "antd";
import {Info} from "@material-ui/icons";
import {NavLink} from 'react-router-dom';

interface RouteType {
    Title: string;
    Path: string;
    Icon: ComponentType<SVGProps<SVGSVGElement>> | FunctionComponent<SVGProps<SVGSVGElement>>;
    Component: ComponentType<any>;
    userRight: string[];
}

type Props = {
    userRight: string;
    history: any;
    setPage: (route: string) => void;
    setIsDrawerVisible: (value: React.SetStateAction<boolean>) => void
};

function SideBar({userRight, setPage, setIsDrawerVisible}: Props) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [clickRedirection, setCickRedirection] = useState<any>([])

    function handlePopupOpen(route: any) {

        setCickRedirection(route)
        setIsPopupOpen(true);
    }

    function handlePopupClose() {
        setIsPopupOpen(false);
    }

    function handlePopupRedirect() {
        handlePopupClose()
        setPage(clickRedirection.Path);
        setIsDrawerVisible(false);
        dataStore.updateCurrentRoute(clickRedirection.Title)
    }

    const handleNavLinkClick = (route: RouteType, event: React.MouseEvent<HTMLAnchorElement>) => {
        if (dataStore.currentRoute === "My Profile" && dataStore.profileFormChanged) {
            event.preventDefault(); // Prevent the NavLink from navigating
            handlePopupOpen(route); // Open the modal for unsaved changes
        } else {
            // No unsaved changes, so we can hide the drawer and update the current route
            setIsDrawerVisible(false);
            dataStore.updateCurrentRoute(route.Title);
            // Perform navigation if necessary
        }
    };

    return (
        <div className="SideBarContainer">
            <img src={logo} className="BigTaxandIcon" alt="Logo" style={{marginBottom: "40px"}}/>
            <div className='SideBarNavBar'>
                {AppRoutes.map((route, index) => (
                    (route.userRight.includes(userRight) || route.userRight.includes("*")) && (
                        <NavLink
                            key={index}
                            to={route.Path}
                            activeClassName='navitemSelected'
                            className='SideBarNavBarItem'
                            onClick={(e) =>handleNavLinkClick(route as RouteType, e)}
                        >
                            <div className='NavbarIcon'>
                                <route.Icon width='24px' fill='#fff'/>
                            </div>
                            <div className="NavbarText">
                                {route.Title}
                            </div>
                        </NavLink>
                    )
                ))}

            </div>
            <Modal
                visible={isPopupOpen}
                title={
                    <div className="create-folder-modal-header">
                        <Info/>
                    </div>
                }
                onCancel={handlePopupClose}
                footer={[
                    <Button key="back" onClick={() => {
                        handlePopupClose()
                    }}>
                        Stay on this page
                    </Button>,
                    <Button
                        onClick={(e) => handlePopupRedirect()}
                        key="submit"
                        type="primary"
                    >
                        Leave this page
                    </Button>,

                ]}
            >
                <span style={{fontWeight: "bold"}}>You have unsaved changes. Do you want to leave ?</span>
            </Modal>
        </div>
    )
}

export default inject("authStore", "dataStore", "messageStore")(observer(SideBar));
