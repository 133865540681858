import React, {useEffect} from 'react';
import AppRouter from './components/Router';
import Authorization from './components/Authorization';
import AppProvider from './AppProvider';
import authStore from './stores/authStore';
import MessageService from './services/messages/MessageService';
import {useEventContext} from "./routes/core/EventContext";
import {API} from "./api";
import {BrowserRouter as Router, Route, Switch, useLocation} from 'react-router-dom';
import {ROUTES} from "./parameters/routes";
import UnsubscribePage from "./routes/unsubscribe-user/UnsubscribePage";
import ResetPassword from "./routes/user-management/login/ResetPassword";
import ResetPasswordFirebse from "./routes/user-management/login/ResetPasswordFirebse";
import ReactGA from 'react-ga4';

function usePageTracking() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);
}


function App() {
    const {dispatch} = useEventContext();
    usePageTracking();

    useEffect(() => {
        async function fetchEvents() {
            const fetchedEvents = await API.getAllEvents();
            dispatch({type: 'SET_EVENTS', payload: fetchedEvents});
        }

        fetchEvents();
    }, [dispatch]);
  return (
      <AppProvider>
          <MessageService />
          <Router>
              <Switch>
                  {/* Public Route: No authentication required */}
                  <Route path={ROUTES.unsubscribe} exact component={UnsubscribePage}/>
                  <Route path={ROUTES.resetPassword} exact component={ResetPassword}/>
                  <Route path={ROUTES.resetPasswordFirebase} exact component={ResetPasswordFirebse}/>
                  {/* Protected Routes: Require authentication */}
                  <Authorization authStore={authStore}>
                      <AppRouter />
                  </Authorization>
              </Switch>
          </Router>
      </AppProvider>
  );
}

export default App;
