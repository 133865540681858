import {inject, observer} from "mobx-react";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {Prefixe, UserData} from "../../../models/general";
import dataStore from "../../../stores/dataStore";
import DocumentItem from "./document-item/documentItem";
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import {Button, Modal, notification, Skeleton, Spin} from "antd";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import styles from "../users/Users.module.scss";
import {loadCardStorage} from "../../../helpers/DocumentHandlers";
import {API} from "../../../api";
import CreateFolderWithPermission from "./components/createFolderWithPermission/CreateFolder";
import {countryRef, databaseRef, taxSpecialismRef} from "../../../services/firebase/firebase";
import {ReactComponent as FolderIcon} from "assets/documents/svg/folderIcon.svg";

const antIcon = <LoadingOutlined style={{ fontSize: 24,color:"white", marginLeft:'5px' }} spin />;
const Documents = () => {
    const [checkCardExistParam,setCheckCardExistParam]=useState(false);
    const [openCreateCard, setOpenCreateCard] = React.useState(false);
    const [folderName,setFolderName]=useState<any>('');
    const [description,setDescription]=useState<any>('');
    const [path]=useState('/files/');
    const [loading, setLoading] = React.useState(true);
    let prefixes:Prefixe[]=dataStore.prefixes;
    const [currentUser,setCurrentUser]=useState<any>("");
    const getCurrentUser = useCallback(async ()=>{
        firebase.auth().onAuthStateChanged(async(user) => {
            if (user) {
                var uid = user.uid;
                const userInfo=await API.getUserInfo(uid as string);
                setCurrentUser(userInfo)}
        });
    },[]);
    const getCountryById = (idCountry:string)=>{
        let countryName;
        countryRef.on('value', async (snap) => {
            let result = snap.val();
            Object.keys(result).forEach(key => {
                if(key===idCountry){
                    countryName = result[key];
                }
            });
        });
        return countryName;
    }
    const getTaxSpecialismById = (idTaxSpecialismName:string)=>{
        let taxSpecialismName;
        taxSpecialismRef.on('value', async (snap) => {
            let result = snap.val();
            Object.keys(result).forEach(key => {
                if(key===idTaxSpecialismName){
                    taxSpecialismName = result[key];
                }
            });
        });
        return taxSpecialismName;
    }
    const handleChangeCountry = useCallback((value) => {
        let listCountry: any[] = [];
        if(!value.includes('')) {
            for (let country in value) {
                listCountry.push(getCountryById(value[country]))
            }
        }
        return listCountry;

    }, []);
    const handleChangeTaxSpecialism = useCallback((value) => {
        let listTaxSpecialism: any[] = [];
        if(!value.includes('')) {
            for (let taxSpecialism in value) {
                listTaxSpecialism.push(getTaxSpecialismById(value[taxSpecialism]))
            }
        }
        return listTaxSpecialism;
    }, []);
    const handleChangeFirm = async (value: any) => {
        let listFirm: any[] = [];
            for (let firm in value) {
                const firmData = await new Promise((resolve, reject) => {
                    databaseRef.ref().child(`firm/${value[firm]}`).on('value', async (snap) => {
                        resolve(snap.val()?.firm);
                    });
                });
                listFirm.push(firmData);
        }

        return listFirm;
    };
    const handleUsers = async (value: any) => {
        let listUsers: string[] = [];
            for (let user in value) {
                if (value[user] !== "" && value[user] !== undefined) {
                    const userInfo: UserData = await new Promise((resolve) => {
                        databaseRef.ref().child(`users/${value[user]}`).on('value', async (snap) => {
                            resolve(snap.val());
                        });
                    });
                    if (userInfo) {
                        listUsers.push(userInfo.firstName + " " + userInfo.lastName);
                    }
                }
        }
        return listUsers;
    };
    const loadPrefixeData = (value: any) => {
        let prefixeData:Prefixe={
            name: value.name,
            permission: {
                country:handleChangeCountry(typeof value.permission?.country === 'string'?(value.permission?.country).split(','):''),
                access:value.permission?.access,
                firm: handleChangeFirm(typeof value.permission?.firm === 'string'?(value.permission?.firm).split(','):''),
                taxSpecialism:handleChangeTaxSpecialism(typeof value.permission?.taxSpecialism === 'string'?(value.permission?.taxSpecialism).split(','):''),
                user:handleUsers((typeof value.permission?.user === 'string'?(value.permission?.user).split(','):'')),
                description:value.permission?.description, name:value.permission?.name, status:value.permission?.status},
            isDirectory: value.isDirectory,
            items: value.items,
            fileUrl: value.fileUrl,
            parent:value.parent
        }
        return  prefixeData;
    };
    /*handles change:begin */
    const handleOpenCreateCard = () => {
        setOpenCreateCard(true);
    };
    const handleCloseCreateCard = () => {
        setOpenCreateCard(false);
    };
    /*check Card existence function:begin */
    const checkFolderExistFunction=()=>{
        setCheckCardExistParam(false);
        const folder=prefixes.filter(p=>p.name===folderName)
        if(folder.length>0) {
            setCheckCardExistParam(true)
        }
    }
    /*create folder for example (Folders Documents,Images......) function:begin  */
    const createCard=async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault()
        setLoading(true)
        try {
            const fakeFile:File=new File([],'fakeFile');
            if (checkCardExistParam) {
                setLoading(false);
                return
            } else {// eslint-disable-next-line
                await firebase.storage().ref('/files'+'/'+folderName+'/fakeFile').put(fakeFile,{customMetadata:
                        {
                            firm:(dataStore.permission.firm).toString(),
                            taxSpecialism:(dataStore.permission.taxSpecialism).toString(),
                            country:(dataStore.permission.country).toString(),
                            users:(dataStore.permission.user)?.toString(),
                            access:(dataStore.permission.access).toString(),
                            status:(dataStore.permission.status).toString(),
                            description:(description).toString(),
                            name:(folderName).toString()
                        }}).then(async res => {
                    if (res) {
                        loadCardStorage(path,setLoading)
                        setLoading(false)
                        handleCloseCreateCard()
                        setFolderName('')
                        setDescription('')
                        notification["success"]({
                            message: "Success",
                            description: `Folder added successfully`,
                            style: {fontFamily: 'ArialBD'},
                        });
                        dataStore.resetPermission()
                    }
                })
            }}
        catch (e){
            notification["error"]({
                message: "Error",
                description: "Error when creating folder",
                style : {fontFamily : 'ArialBD'},
            });
        }
    }

    // Function definition with passing two arrays
    function findCommonElement(array1: any[], array2: any[]) {
        // Loop for array1
        for (let i = 0; i < array1.length; i++) {
            // Loop for array2
            for (let j = 0; j < array2.length; j++) {
                // Compare the element of each and
                // every element from both of the
                // arrays
                if (array1[i] === array2[j]) {
                    // Return if common element found
                    return true;
                }
            }
        }
    }

    function handleAccess(folder: Prefixe) {
        let users: any[] = [];
        let firms: any[] = [];
        let taxSpecialisms: any[] = [];
        let countries: any[] = [];
        let status: any[] = [];
        let result: any[] = [];
        if (currentUser.right === "Global administrator"){
            result.push(true)
        }
        // if the current user is not a global administrator
        else {
            if (folder.permission?.status && typeof folder.permission?.status === 'string') {
                status = (folder.permission?.status as string).split(',');
                // if status is public
                if(status.includes('public'))
                    result.push(true)
                // if status is private
                else {
                    if (folder.permission?.user && typeof folder.permission?.user === 'string') {
                        users = (folder.permission?.user as string).split(',');
                        result.push(users.includes(currentUser.id))
                    }
                    if (folder.permission?.country && typeof folder.permission?.country === 'string') {
                        countries = handleChangeCountry((folder.permission?.country as string).split(','));
                        result.push(countries.includes(currentUser.country))
                    }
                    if (folder.permission?.taxSpecialism && typeof folder.permission?.taxSpecialism === 'string') {
                        taxSpecialisms = handleChangeTaxSpecialism((folder.permission?.taxSpecialism as string).split(','));
                        if(taxSpecialisms.length>0 && (currentUser.taxSpecialisme && currentUser.taxSpecialisme.length>0))
                            result.push(findCommonElement(taxSpecialisms, currentUser.taxSpecialisme))
                        else if (!currentUser.taxSpecialisme || currentUser.taxSpecialisme.length===0){
                            result.push(false)
                        }
                    }
                    if (folder.permission?.firm && typeof folder.permission?.firm === 'string') {
                        firms = (folder.permission?.firm ).split(',');
                        result.push(firms.includes((currentUser?.firm?.id)?.toString()))
                    }
                }
            }
        }
        return !result.includes(false);
    }
    /*create folder function:end */
    useEffect(() => {
        loadCardStorage(path,setLoading)
        getCurrentUser().then();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div  className={styles.root}>
            <div className="ButtonContainer">
                <Button type="primary"
                        hidden={currentUser.right !== "Global administrator"}
                        size='large'
                        style={{marginRight: "10px", fontFamily: 'Arial', fontSize: "14px"}}
                        icon={<PlusOutlined/>}
                        onClick={handleOpenCreateCard}>
                    Create
                </Button>
            </div>
            <div className="list-documents-container">
                <Skeleton loading={loading} active>
                    {prefixes.map((folder, index) => (
                        handleAccess(folder) && (
                            <div className="document-item-container" key={index}>
                                <DocumentItem
                                    key={index}
                                    currentUser={currentUser.right}
                                    prefixeData={loadPrefixeData(folder)}
                                    firmIds={
                                        typeof folder.permission?.firm === 'string'
                                            ? folder.permission?.firm.split(',')
                                            : ''
                                    }
                                    userIds={
                                        typeof folder.permission?.user === 'string'
                                            ? folder.permission?.user.split(',')
                                            : ''
                                    }
                                />
                            </div>
                        )
                    ))}
                </Skeleton>
            </div>
            {/* Create Folder Modal:begin */}
            <Modal
                visible={openCreateCard}
                title={
                    <div className="create-folder-modal-header">
                        <FolderIcon />
                        Create Folder
                    </div>
                }
                onCancel={handleCloseCreateCard}
                destroyOnClose
                footer={[
                    <Button key="back" onClick={() => {
                        handleCloseCreateCard()
                    }}>
                        Return
                    </Button>,
                    <Button
                        disabled={checkCardExistParam || !folderName}
                        onClick={(e)=>createCard(e)}
                        key="submit"
                        type="primary"
                    >
                        Create
                        <Spin spinning={loading}  indicator={antIcon} />
                    </Button>,

                ]}
            >
                <CreateFolderWithPermission currentPath={path} folderName={folderName} setFolderName={setFolderName}
                                            description={description} setDescription={setDescription}  checkFolderExistParam={checkCardExistParam} checkFolderExistFunction={checkFolderExistFunction} />
            </Modal>
            {/* Create Folder Modal:end */}
        </div>
    );
}
export default inject("authStore", "dataStore", "messageStore")(observer(Documents));
