export const ROUTES = {
    login: `${process.env.PUBLIC_URL}/login`,
    forgotPassword: `${process.env.PUBLIC_URL}/forgotPassword`,
    resetPassword: `${process.env.PUBLIC_URL}/resetPassword/:token`,
    resetPasswordFirebase: `${process.env.PUBLIC_URL}/resetPasswordFirebase`,
    home: `${process.env.PUBLIC_URL}/profiles`,
    news: `${process.env.PUBLIC_URL}/news`,
    events: `${process.env.PUBLIC_URL}/events`,
    users: `${process.env.PUBLIC_URL}/taxanders`,
    firms: `${process.env.PUBLIC_URL}/firms`,
    user: `${process.env.PUBLIC_URL}/user/:userId`,
    profile: `${process.env.PUBLIC_URL}/profile`,
    approvals: `${process.env.PUBLIC_URL}/approvals`,
    professionalsFirm: `${process.env.PUBLIC_URL}/professionalsFirm`,
    documents: `${process.env.PUBLIC_URL}/documents`,
    document: `${process.env.PUBLIC_URL}/document/:documentName`,
    createusersInfoUrl(userId: number) {
        return `${process.env.PUBLIC_URL}/user/${userId}`;
    },
    groupChat: `${process.env.PUBLIC_URL}/groupchat`,
    unsubscribe: `${process.env.PUBLIC_URL}/unsubscribe`,
    dashboard: `${process.env.PUBLIC_URL}/dashboard`,
    // Add a route for 404
    notFound: `${process.env.PUBLIC_URL}/404`,
}
