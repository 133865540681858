import firebase from "firebase";
import {Prefixe, Item} from "models/general";
import dataStore from "stores/dataStore"
import { deleteFileService, deleteFolderService, loadDataStorage, loadFileMetadata } from "services/documents/DocumentDetailsServices";
import { notification } from "antd";
import { FormEvent } from "react";





export const loadDocumentStorage = async (
  path: string,
  setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
  setParent: React.Dispatch<any>,
  params: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  let prefixesDataStore: any = [];
  let itemsDataStore: any = [];
  setLoading(true);
  dataStore.resetPrefixes();
  dataStore.resetItems();
  if (path) {
    setCurrentPath(path.startsWith('/') ?path : '/'+path);
  }
  const result = await loadDataStorage(
    path ? path : "files/" + params["documentName"]
  );
  if (result.prefixes.length > 0) {
    for (const value of result?.prefixes) {
      setParent(value.parent?.parent?.fullPath);
      let folder: Prefixe = {
        name: value.name,
        isDirectory: true,
        fileUrl: value.fullPath,
        items: [],
        parent: value.parent?.fullPath as any,
      };
      prefixesDataStore.push(folder);
    }
    dataStore.updatePrefixes(prefixesDataStore);
  }
  if (result.items.length > 0) {
    await Promise.all(
      result?.items.map(async (value: any) => {
        const metadataItem = await loadFileMetadata(value.location.path_);
        setParent(value?.parent?.parent?.fullPath);
        let file: Item = {
          id:metadataItem.customMetadata?.id,
          name: value.name.substring(value.name.indexOf('#')+1,),
          isDirectory: false,
          size: metadataItem.size,
          fileUrl: value.fullPath,
          lastUpdate: new Date(metadataItem.updated),
          parent: value.parent?.fullPath as any,
          version:metadataItem.customMetadata?.version,
          isLastVersion:metadataItem.customMetadata?.isLastVersion
        };
        if(value.name!=='fakeFile' && metadataItem.customMetadata?.isLastVersion==="Yes"){itemsDataStore.push(file);}
      })
    );
    dataStore.updateItems(itemsDataStore);
  }
  setLoading(false);
};


export const deleteMultipleFileService=async (
    item:Item,
    currentPath:string,
    setCurrentPath:React.Dispatch<React.SetStateAction<string>>,
    setParent: React.Dispatch<React.SetStateAction<string>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    params: any) => {
    try {
      return updateItemsFileVersionSelected(item)
            .then(async res => {
                const itemsSelected=dataStore.itemsFileVersionSelected;
                for(let item of itemsSelected){
                    await firebase.storage().ref(item.fileUrl).delete()
                }
                dataStore.updateItems(dataStore.items.filter(ite=>ite.fileUrl!==item.fileUrl))
                notification["success"]({
                    message: "Success",
                    description: `File deleted successfully`,
                    style: { fontFamily: "ArialBD" },
                });
                return true;
            })
            .catch(error => {
                // eslint-disable-next-line
                notification["error"]({
                    message: "Error",
                    description: "Error when deleting file",
                    style: { fontFamily: "ArialBD" },
                });
                return false; // Move the return false inside the catch block
            });
    } catch (error) {
        notification["error"]({
            message: "Error",
            description: "Error when deleting file",
            style: { fontFamily: "ArialBD" },
        });
        return false;
    }
}



const updateItemsFileVersionSelected = async (item: Item)=>{
    let itemsVersionDataStore: any = [];
    const result = await loadDataStorage(
        '/'+item.parent
    );
    if (result.items.length > 0) {
        await Promise.all(
            result?.items.map(async (value: any) => {
                const metadataItem = await loadFileMetadata(value.location.path_);
                if(value.name!=='fakeFile' && item.id===metadataItem.customMetadata?.id){
                    let file: Item = {
                        id:metadataItem.customMetadata?.id,
                        name: value.name.substring(value.name.indexOf('#')+1,),
                        isDirectory: false,
                        size: metadataItem.size,
                        fileUrl: value.fullPath,
                        lastUpdate: new Date(metadataItem.updated),
                        parent: value.parent?.fullPath as any,
                        version:metadataItem.customMetadata?.version,
                        isLastVersion:metadataItem.customMetadata?.isLastVersion
                    };
                 itemsVersionDataStore.push(file)
                }
            })
        );
        dataStore.updateItemsFileVersionSelected(itemsVersionDataStore)
    }
}


export const deleteFile = async (
  e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>,
  item: Item | Prefixe,
  currentPath: string,
  setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
  setParent: React.Dispatch<React.SetStateAction<string>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  params: any
) => {
  e.stopPropagation();
  try {
    await deleteFileService(item.fileUrl).then((res) => {
      if (res) {
        dataStore.updateItems(dataStore.items.filter(it=>it.fileUrl===item.fileUrl))
        notification["success"]({
          message: "Success",
          description: `File deleted successfully`,
          style: { fontFamily: "ArialBD" },
        });
      }
    });
  } catch (error) {
    notification["error"]({
      message: "Error",
      description: "Error when deleting file",
      style: { fontFamily: "ArialBD" },
    });
  }
};

export const createFolder = async (
  e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>,
  setLoadingSave: (value: React.SetStateAction<boolean>) => void,
  checkFolderExistParam : boolean,
  currentPath: string,
  setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
  setParent: React.Dispatch<React.SetStateAction<string>>,
  params: any,
  folderName: string,
  setFolderName: React.Dispatch<React.SetStateAction<string>>,
  setIsNewFolder: React.Dispatch<React.SetStateAction<boolean>>
) => {
  e.preventDefault();
  setLoadingSave(true);
  try {
    const fakeFile: File = new File([], "fakeFile");
    if (checkFolderExistParam) {
      setLoadingSave(false);
      return;
    } else {
      await firebase
        .storage()
        .ref(currentPath + "/" + folderName + "/fakeFile")
        .put(fakeFile)
        .then(async (res) => {
          if (res) {
            loadDocumentStorage(
              currentPath,
              setCurrentPath,
              setParent,
              params,
              setLoadingSave,
            );
            setLoadingSave(false);
            setFolderName("");
            setIsNewFolder(false);
            notification["success"]({
              message: "Success",
              description: `Folder added successfully`,
              style: { fontFamily: "ArialBD" },
            });
          }
        });
    }
  } catch (err) {
    notification["error"]({
      message: "Error",
      description: "Error when creating folder",
      style: { fontFamily: "ArialBD" },
    });
  }
};


export const loadFileVersion=async (item: Item, setLoadingFileVersion: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoadingFileVersion(true)
    dataStore.updateFileVersionSelected(item);
    dataStore.resetItemsVersions()
    const result = await loadDataStorage(
       '/'+item.parent
    );
    let itemsVersionDataStore: any = [];
    if (result.items.length > 0) {
        await Promise.all(
            result?.items.map(async (value: any) => {
                const metadataItem = await loadFileMetadata(value.location.path_);
                let file: Item = {
                    id:metadataItem.customMetadata?.id,
                    name: value.name.substring(value.name.indexOf('#')+1,),
                    isDirectory: false,
                    size: metadataItem.size,
                    fileUrl: value.fullPath,
                    lastUpdate: new Date(metadataItem.updated),
                    parent: value.parent?.fullPath as any,
                    version:metadataItem.customMetadata?.version,
                    isLastVersion:metadataItem.customMetadata?.isLastVersion
                };
                if(value.name!=='fakeFile' && item.id===metadataItem.customMetadata?.id){itemsVersionDataStore.push(file);}
            })
        );
        dataStore.updateItemsFileVersionSelected(itemsVersionDataStore.sort(function (a:Item,b:Item){return parseInt(b.version)- parseInt(a.version)}))
    }
    setLoadingFileVersion(false);
}

export const loadCardStorage = async (
    path: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
    let prefixesDataStore: any = [];
    setLoading(true);
    dataStore.resetPrefixes();
    const result = await loadDataStorage(path);
    if (result.prefixes.length > 0) {
        await Promise.all(
            result?.prefixes.map(async (value: any) => {
                const metadataItem = await loadFileMetadata(value.location.path_+"/fakeFile");
                let folder: Prefixe = {
                    name: value.name,
                    isDirectory: true,
                    items: [],
                    fileUrl: value.fullPath,
                    parent: value.parent?.fullPath,
                    permission: {
                        user:metadataItem?.customMetadata?.users,
                        firm:metadataItem?.customMetadata?.firm,
                        taxSpecialism:metadataItem?.customMetadata?.taxSpecialism,
                        country:metadataItem?.customMetadata?.country,
                        access:metadataItem?.customMetadata?.access,
                        status:metadataItem?.customMetadata?.status,
                        description:metadataItem?.customMetadata?.description,
                        name:metadataItem?.customMetadata?.name
                    },
                };
                prefixesDataStore.push(folder);
            }))
        prefixesDataStore=prefixesDataStore.sort((a: Item, b: Item) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1))
        dataStore.updatePrefixes(prefixesDataStore);
    }
    setLoading(false);
};


  /*delete folder function:begin */
export const deleteFolder = async (
  e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent> | undefined,
  prefix: Prefixe | Item,
  currentPath: string,
  setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
  setParent: React.Dispatch<React.SetStateAction<string>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  params: any
) => {
  e?.stopPropagation();
  try {

    await deleteFolderService(prefix.fileUrl).then((res) => {
      if (res) {
        const a  =dataStore.prefixes.filter(pre=>{return  !pre.fileUrl.startsWith(prefix.fileUrl)})
        const b =  dataStore.items.filter(ite=>{return  !ite.fileUrl.startsWith(prefix.fileUrl)})
          dataStore.updatePrefixes(a);
          dataStore.updateItems(b)
        notification["success"]({
          message: "Success",
          description: `Folder deleted successfully`,
          style: { fontFamily: "ArialBD" },
        });
      }
    });
  } catch (error) {
    notification["error"]({
      message: "Error",
      description: "Error when deleting folder",
      style: { fontFamily: "ArialBD" },
    });
  }
};

export const loadFolderParentAccess=async (folderName: string) => {
    const result = await firebase.storage().ref('/files/' + folderName+"/fakeFile").getMetadata()
    let newAccess:any={
        folderName:folderName,
        access:result.customMetadata?.access
    }
    dataStore.updateFolderParentAccess(newAccess)
}

/*delete folder function:begin */
export const deleteCard = async (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    prefix: Prefixe | Item,
    path: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
    e?.stopPropagation();
    try {
        await deleteFolderService(prefix.fileUrl).then((res) => {
            if (res) {
                loadCardStorage(path,setLoading)
                notification["success"]({
                    message: "Success",
                    description: `Folder deleted successfully`,
                    style: { fontFamily: "ArialBD" },
                });
            }
        });
    }
    catch (error) {
        notification["error"]({
            message: "Error",
            description: "Error when deleting folder",
            style: { fontFamily: "ArialBD" },
        });
    }
};

