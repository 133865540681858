import React, {useEffect, useState} from "react";
import {ReactComponent as MenuIcon} from "assets/menu.svg";
import {Avatar} from "antd";
import {UserData} from "../../models/general";
import {ReactComponent as LogoutIcon} from "assets/Logout.svg";
import {ReactComponent as Delete} from "assets/event-calender/notification/svg/delete.svg";
import authStore from "stores/authStore";
import {inject, observer} from "mobx-react";
import dataStore from "stores/dataStore";
import {HeaderText} from "../../Utils/Constants/HeaderText";
import Notification from "../../routes/core/notification/Notification";
import ListCountDown from "../../routes/core/notification/list_count_down/ListCountDown";
import LandingModel from "../../routes/core/landingModel/LandingModel";
import {useEventContext} from "../../routes/core/EventContext";
// Firebase imports
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// Firebase imports
import {API} from "../../api";
import messageStore from "../../stores/messageStore";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

type Props = {
  history: any;
  currentRoute : string | undefined;
  userinfo: UserData | undefined;
  setIsDrawerVisible: (value: React.SetStateAction<boolean>) => void
};

const DropdownItem = (props: any) => {
  return (
    <div className="menuItem" onClick={props.onClick}>
      <span className="icon-button">{props.lefticon}</span>
      {props.children}
    </div>
  );
};
interface DropDownType {
  opened: boolean;
}

const DropDown: React.SFC<DropDownType> = ({ opened }) => {
  return (
    <div className={opened ? "Dropdown fadein" : "Dropdown fadeout"}>
      <DropdownItem
        lefticon={<LogoutIcon />}
        onClick={() => authStore.logout()}
      >
        Logout
      </DropdownItem>
    </div>
  );
};
const  Header = ({setIsDrawerVisible}: Props) => {
  const {events} = useEventContext();
  const [displayPopup, setDisplayPopup] = useState(true);
  const [displayPopupLandingModel, setDisplayPopupLandingModel] = useState(true);
  const [showLandingModal, setShowLandingModal] = useState(false);
  const {t} = useTranslation();
  const [userInfoData ,setUserInfo] = useState<UserData>();
  const userInfo = dataStore.userInfo;
  // Use location to get the pathname of the DocumentDetails page
  const location = useLocation();

  // Get the current page pathname
  const currentPath = location.pathname;

  // Function to toggle between displaying events and notifications
  const handleToggleDisplayPopup = () => {
    setDisplayPopup(!displayPopup);
  };

  // Function to toggle displaying popupLanding Model and make as reader
  const HidePopupLandingModel = () => {
    setDisplayPopupLandingModel(!displayPopupLandingModel);
    firebase.auth().onAuthStateChanged(async function (user) {
          if (user) {
            userInfoData!.showLandingModal = false;
            const res = await API.updateUser(userInfoData!, userInfoData!.id);
            if (res) {
              console.log("User updated successfully")
            }
            else {
              messageStore.snackbar({
                message: t("Failed to update user"),
                type: "error",
              });
            }
          }
        }
    );
    setDisplayPopup(!displayPopup);
  }

  // Function to toggle displaying popupLanding Model
  const handleToggleDisplayPopupLandingModel = () => {
    setDisplayPopupLandingModel(!displayPopupLandingModel);
    setDisplayPopup(!displayPopup);
  };

  const [opened, setOpened] = React.useState(false);
  
  // get first initials of full name
  const getInitials = (name: string) => {
    const names = name.split(" ");
    return names.map((n) => n[0]).join("");
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async function (user) {
          if (user) {
            const userinfo = await API.getUserInfo(user.uid)
            setUserInfo(userinfo);
            if (userinfo.showLandingModal){
              setShowLandingModal(userinfo.showLandingModal)
            }
          }
        }
    );
  }, []);


  return (
      <>
    <div className="Header">
      <Notification/>
      {showLandingModal ? (
        <>
          <div  className={`popup_Bg_Landing ${displayPopupLandingModel ? 'rbt-active' : ''}`}>
            <div className="rbt-hide-popup" onClick={handleToggleDisplayPopupLandingModel}>
              <Delete onClick={handleToggleDisplayPopupLandingModel} />
            </div>
            <LandingModel onButtonClick={HidePopupLandingModel}/>
          </div>
          {events.length > 0 && events.some(event => event.isInCountdown) && (
              <div  className={`popup_Bg ${displayPopup ? '' : 'rbt-active'}`}>
                <div className="rbt-hide-popup" onClick={handleToggleDisplayPopup}>
                  <Delete onClick={handleToggleDisplayPopup} />
                </div>
                <ListCountDown />
              </div>
          )}
        </>
      ) : (
          <>
            {events.length > 0 && events.some(event => event.isInCountdown) && (
                    <div  className={`popup_Bg ${displayPopup ? 'rbt-active' : ''}`}>
                      <div className="rbt-hide-popup" onClick={handleToggleDisplayPopup}>
                        <Delete onClick={handleToggleDisplayPopup} />
                      </div>
                      <ListCountDown />
                    </div>
            )}
          </>
      )}

      <div className="Username">
        <div style={{display : "flex" , alignItems : "center"}}>
          <div className="MenuIcon"> 
            <MenuIcon onClick={() => setIsDrawerVisible(true)} width={30} height={30} style={{marginRight : '10px'}} />
          </div>
          {(dataStore.currentRoute === undefined && currentPath.includes('/document/')) ? "Documents" : dataStore.currentRoute}
        </div>
      </div>
      <div className="UserInfo" onClick={() => setOpened(!opened)}>
        <Avatar
            size={30}
            src={userInfo?.avatarUrl}
            style={{ marginRight: "10px" }}
        >
          {userInfo?.avatarUrl === undefined || userInfo?.avatarUrl === ''
              ? getInitials(`${userInfo?.firstName + " " + userInfo?.lastName}`)
              : null}
        </Avatar>
        <span className="usernameTitle">
        {userInfo?.firstName + " " + userInfo?.lastName}
      </span>
      </div>
      <DropDown opened={opened} />
    </div>
        {dataStore.currentRoute!=='News' && dataStore.currentRoute!=='Events' && dataStore.currentRoute!=='Approvals' && dataStore.currentRoute!=='Dashboard' && dataStore.currentRoute!=='My firm’s professionals' ?
            <div className={'headerIntroContainer'}>
              {dataStore.currentRoute === 'Taxanders' ? <p className={'introMessage'}>{HeaderText.Taxanders}</p> : null}
              {dataStore.currentRoute === 'Firms' ? <p className={'introMessage'}>{HeaderText.Firms}</p> : null}
              {dataStore.currentRoute === 'My Profile' ? <p className={'introMessage'}>{HeaderText.MyProfile}</p> : null}
              {(dataStore.currentRoute === 'Documents' || currentPath.includes('/documents')) ? <p className={'introMessage'}>{HeaderText.Documents}</p> : null}
              {(dataStore.currentRoute === undefined && currentPath.includes('/document/')) ? <p className={'introMessage'}>{HeaderText.Documents}</p> : null}
            </div>
            :
            null
        }
      </>
  );
}

export default inject("authStore", "dataStore", "messageStore")(observer(Header));
